import React from 'react'

import PostCard from './PostCard'
class PostSection extends React.Component {
  static defaultProps = {
    posts: [],
    title: '',
    limit: 6,
    showLoadMore: true,
    loadMoreTitle: 'Load More',
    perPageLimit: 6
  }

  state = {
    limit: this.props.limit
  }

  increaseLimit = () =>
    this.setState((prevState) => ({
      limit: prevState.limit + this.props.perPageLimit
    }))

  render() {
    const { posts, title, showLoadMore, loadMoreTitle } = this.props,
      { limit } = this.state,
      visiblePosts = posts.slice(0, limit || posts.length)

    return (
      <>
        {title && <h2>{title}</h2>}
        {!!visiblePosts.length && (
          <div className="max-w-sm md:max-w-lg lg:max-w-4xl xl:max-w-2x mx-auto grid grid-cols-1 md:grid-cols-3">
            {visiblePosts.map((post, index) => (
              <PostCard key={post.title + index} {...post} />
            ))}
          </div>
        )}
        {showLoadMore && visiblePosts.length < posts.length && (
          <div className="text-center m-8">
            <button
              className="bg-orange-sherpa text-white font-bold py-2 px-4 rounded-full"
              onClick={this.increaseLimit}
            >
              {loadMoreTitle}
            </button>
          </div>
        )}
      </>
    )
  }
}

export default PostSection
