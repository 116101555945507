import React from 'react'
import { Link } from 'gatsby'

import BlogSearch from './BlogSearch'

const PostCategoriesNav = ({ categories, enableSearch }) => (
  <div className="max-w-sm sm:max-w-md md:max-w-lg lg:max-w-xl xl:max-w-2x mx-auto">
    <div className="flex ">
      <div class="w-full flex flex-wrap md:flex-row  text-sm font-bold  my-2  p-4 md:p-0 py-2">
        <Link
          className="border-solid border-2 hover:border-orange-sherpa
           rounded py-1 px-3 mr-2 mb-2"
          activeStyle={{
            borderColor: '#ff9000'
          }}
          exact="true"
          to={`/blog/`}
        >
          All
        </Link>

        {categories.map((category, index) => (
          <Link
            exact="true"
            className="border-solid border-2 hover:border-orange-sherpa rounded py-1 px-3 mr-2 mb-2"
            activeStyle={{
              borderColor: '#ff9000',
            }}
            key={category.title + index}
            to={category.slug}
          >
            {category.title}
          </Link>
        ))}
        <div className="pt-2 md:pt-0">{enableSearch && <BlogSearch />}</div>
      </div>
    </div>
  </div>
)

export default PostCategoriesNav
